import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');

	// Navigation levels

	if ($(window).width() > 768) {
		$('.navbar .dropdown').hover(function() {
			$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
		}, function() {
			$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
		});

		$('.navbar .dropdown > a').click(function(){
			location.href = this.href;
		});
	}

});